import { Button, Col, message, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  InboxOutlined,
  ArrowRightOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import Verificationimg from "../../../src/assets/images/verified-contract.png";
import { useHistory } from "react-router-dom";
import "./verify.less";
// import newLogo from "../../../../../assets/images/newLogo.png";
import newLogo from "../../../src/assets/images/verify-logo.png";
import { getcontractVerify } from "./Verify.Action";
import VerifiedDetails from "./Verifydetails";
import { verifydatareset } from "./Verify.Slice";
const { Dragger } = Upload;
const Index = (props) => {
  const [filedata, setFileData] = useState({});
  const dispatch = useDispatch();
  const { verify } = props;
  const history = useHistory();

  const { issubmitted = false, ispdfVerifying = false } = verify;

  const onSubmit = () => {
    const data = new FormData();

    data.append(`pdf`, filedata);

    dispatch(getcontractVerify({ form_data: data }));
  };
  const uploadprops = {
    // name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".pdf",
    listType: "picture",
    thumbUrl:
      "https://help.wprentals.org/wp-content/uploads/2017/12/wprentals_upload_id_san.png",
    // multiple: true,
    onRemove: (file) => {
      setFileData({});
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileData(file);

      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };
  useEffect(() => {
    return () => {
      dispatch(verifydatareset());
    };
  }, [dispatch]);
  return (
    <Row className="Verify_main">
      <Col span={7} className="Verify_left_content">
        <div className="Verify_left_content_box">
          <div className="Verify_left_content_title">
            <img className="verifiable_logo" alt="" src={newLogo}></img>
          </div>
          <div className="Verify_left_content_detail">
            Secure, Verify, and <br /> Scale Your Agreements Effortlessly
          </div>
          <div className="Verify_left_content_detailed">
            {/* Verify and audit your signed contracts{" "} */}
          </div>
        </div>
        <img
          alt="verify"
          style={{ width: "100%", height: "55vh" }}
          src={Verificationimg}
        ></img>
      </Col>
      <Col span={17} className="Verify_right_content">
        <div className="verify_right_content_first">
          <Button
            onClick={() => {
              history.push("/");
              dispatch(verifydatareset());
            }}
            className="btn_home_verify"
            type="text"
            icon={<HomeOutlined style={{ fontSize: "20px" }} />}
          ></Button>
        </div>

        <div className="verify_right_content_second">
          {!issubmitted && (
            <div>
              <div className="Verify_right_content_title">
                Start Verifying Your Contract
              </div>
              <Dragger className="Verify_dragger" {...uploadprops}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Drag and drop file or Browse</p>
                <p className="ant-upload-hint">Support only pdf documents</p>
              </Dragger>
              <div className="verify_btn_box">
                <Button
                  disabled={!filedata?.name}
                  loading={ispdfVerifying}
                  onClick={() => {
                    onSubmit();
                  }}
                  className="verify_btn"
                >
                  <span className="verify_btn_title">
                    Verify &nbsp;
                    <ArrowRightOutlined />
                  </span>
                </Button>
              </div>
            </div>
          )}
          {issubmitted && <VerifiedDetails fileData={filedata} {...props} />}
        </div>
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  user: state.user,
  staff: state.staff,
  global: state.global,
  content: state.content,
  workflow: state.workflow,
  verify: state.verify,
}))(injectIntl(Index));
